<script setup>

</script>

<template>
  <div class="marquee-wrap">
    <div class="marquee comic">ZUZO is brave | ZUZO escaped the matrix | ZUZO loves you | ZUZO is TOP G | ZUZO iz chilled | ZUZO is swag | ZUZO is lit | ZUZO is awesome | ZUZO is rich | ZUZO is sexy | ZUZO is based | ZUZO hates jeets | ZUZO brings luck | ZUZO is strong | Chicks love ZUZO  | ZUZO loves donuts | ZUZO loves pizza | ZUZO is cool | ZUZO is our saviour | ZUZO iz happy | ZUZO is the Master | </div>
    <div class="marquee comic" aria-hidden="true">ZUZO is brave | ZUZO escaped the matrix | ZUZO loves you | ZUZO is TOP G | ZUZO iz chilled | ZUZO is swag | ZUZO is lit | ZUZO is awesome | ZUZO is rich | ZUZO is sexy | ZUZO is based | ZUZO hates jeets | ZUZO brings luck | ZUZO is strong | Chicks love ZUZO  | ZUZO loves donuts | ZUZO loves pizza | ZUZO is cool | ZUZO is our saviour | ZUZO iz happy | ZUZO is the Master | </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/css/variables.less";

.marquee-wrap {
  background-color: @orange;
  padding: 16px 0;
  border-bottom: 3px solid #000;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 20px;
}

.marquee {
  font-size: 16px;
  font-weight: 700;
  position: relative;
  white-space: nowrap;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}
</style>