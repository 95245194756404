<script setup>

</script>

<template>
  <div id="reward" class="reward">
    <div class="container">
      <div class="reward__inner">
        <div class="reward__title comic">
          <img class="gif" :src="require('@/assets/images/reward/like-2.gif')" alt="like">
          <span>EARLY SUPPORTERS WILL BE REWARDED</span>
          <div class="hashtag comic">#ZUZO</div>
          <div class="hashtag-laptop-1 comic">#ZUZOCHAD</div>
          <div class="hashtag-laptop-2 comic">#ZUZOBRO</div>
          <img class="gif-laptop-1" :src="require('@/assets/images/reward/like-2.gif')" alt="like">
          <img class="gif-laptop-2" :src="require('@/assets/images/reward/sub.gif')" alt="like">
        </div>
        <div class="reward__subtitle">
          <div>ZUZO knows <span>every</span> one of you bros</div>
        </div>
        <div class="reward__items">
          <div class="comic">#ZUZOCHAD</div>
          <img :src="require('@/assets/images/reward/sub.gif')" alt="like">
          <img :src="require('@/assets/images/reward/like-1.gif')" alt="like">
        </div>
        <div class="reward__bottom">
          <div class="reward__text">
            <span class="comic">VALUABLE GIFTS</span>
            <span>ZUZO is not trying to bribe you. No no. ZUZO is just showing his appreciation for your support</span>
          </div>
          <div class="reward__text">
            <span class="comic">HIGHER MCAP = MORE REWARS</span>
            <span>More info - TBA.  Support ZUZO and stay tuned Bro</span>
          </div>
          <div class="reward__img">
            <div class="zuzo-reward">
              <img class="gif-laptop-1" :src="require('@/assets/images/reward/like-1.gif')" alt="like">
              <img class="gif-laptop-2" :src="require('@/assets/images/reward/like-1.gif')" alt="like">
              <img class="gif-laptop-3" :src="require('@/assets/images/reward/sub.gif')" alt="like">
              <img class="gif-desktop-1" :src="require('@/assets/images/reward/sub.gif')" alt="like">
              <img class="gif-laptop-4" :src="require('@/assets/images/reward/sub.gif')" alt="like">
              <img class="zuzo-reward__pers" :src="require('@/assets/images/reward/zuzo-reward.png')" alt="zuzo">
              <img class="zuzo-reward__like" :src="require('@/assets/images/reward/like-2.gif')" alt="like">
              <div class="zuzo-reward__phrase">
                <img :src="require('@/assets/images/main/text-bubble.png')" alt="text-bubble">
                <span class="comic">love u Bro. Thnx for supporting me</span>
              </div>
              <div class="hashtag comic">#ZUZOBRO</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/css/variables.less";

.reward {
  border-bottom: 3px solid #000;
  background-color: @orange;
  padding: 24px 0 0;
  overflow: hidden;

  &__title {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 30px;
    padding: 0 20px;
    line-height: 1.3;
    position: relative;
    display: flex;
    justify-content: center;

    @media @laptop {
      font-size: 48px;
    }

    @media @desktop {
      font-size: 56px;
    }

    & .gif {
      position: absolute;
      top: 0;
      left: -10px;
      width: 100px;

      @media @laptop {
        width: 130px;
        top: 80px;
      }

      @media @desktop {
        width: 250px;
        top: 0px;
        left: -40px;
      }
    }

    & span {
      position: relative;

      @media @laptop {
        display: block;
        max-width: 630px;
      }

      @media @desktop {
        max-width: 860px;
      }
    }

    & .hashtag {
      font-size: 15px;
      font-weight: 500;
      position: absolute;
      top: 0;
      right: 0;
      transform: rotate(9deg);

      @media @laptop {
        top: 130px;
        right: unset;
        left: 150px;
        font-size: 36px;
      }

      @media @desktop {
        font-size: 44px;
        left: 200px;
      }
    }

    & .hashtag-laptop-1 {
      display: none;
      position: absolute;
      left: 50px;
      top: 400px;
      transform: rotate(-8deg);
      font-size: 36px;
      font-weight: 500;

      @media @laptop {
        display: block;
      }

      @media @desktop {
        font-size: 44px;
        top: 450px;
      }
    }

    & .hashtag-laptop-2 {
      display: none;
      position: absolute;
      right: 70px;
      top: 230px;
      transform: rotate(3deg);
      font-size: 36px;
      font-weight: 500;

      @media @laptop {
        display: block;
      }

      @media @desktop {
        font-size: 44px;
        top: 380px;
      }
    }

    & .gif-laptop-1 {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 130px;

      @media @laptop {
        display: block;
      }

      @media @desktop {
        width: 200px;
      }
    }

    & .gif-laptop-2 {
      display: none;
      position: absolute;
      right: 200px;
      top: 60px;
      width: 106px;

      @media @laptop {
        display: block;
      }

      @media @desktop {
        right: 240px;
        top: 120px;
        width: 155px;
      }
    }
  }

  &__subtitle {
    display: flex;
    justify-content: center;

    & > div {
      background-color: #fff;
      border: 2px solid #000;
      border-bottom: 5px solid #000;
      border-radius: 10px;
      font-size: 19px;
      font-weight: 500;
      padding: 10px 8px;

      @media @laptop {
        font-size: 28px;
        padding: 16px 24px;
      }

      @media @desktop {
        font-size: 36px;
      }
    }

    & span {
      font-weight: 700;
    }
  }

  &__items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    @media @laptop {
      display: none;
    }

    & *:nth-child(1) { font-size: 15px; transform: rotate(-8deg); }
    & *:nth-child(2) { width: 50px; transform: translateY(-10px); }
    & *:nth-child(3) { width: 74px; transform: rotate(12deg); }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;

    @media @laptop {
      align-items: flex-end;
      justify-content: space-between;
      padding-top: 130px;
    }

    @media @desktop {
      justify-content: center;
    }
  }

  &__text {

    @media @desktop {
      margin-left: 80px;
    }

    &:nth-child(1) {
      flex: 0 0 calc(50% - 8px);

      @media @laptop {
        flex: 0 0 370px;
        order: 1;
      }

      @media @desktop {
        flex: 0 0 500px;
      }
    }

    &:nth-child(2) {
      flex: 0 0 calc(50% - 8px);

      @media @laptop {
        flex: 0 0 370px;
        order: 3;
      }

      @media @desktop {
        flex: 0 0 400px;
      }
    }

    & span {
      display: block;
      text-align: center;
      line-height: 1.2;

      @media @laptop {
        text-align: left;
      }

      &:nth-child(1) { font-weight: 700; font-size: 16px; margin-bottom: 10px; }
      &:nth-child(2) { font-weight: 400; font-size: 14px; }

      @media @laptop {
        &:nth-child(1) { font-size: 36px; margin-bottom: 24px; }
        &:nth-child(2) { font-size: 24px; padding-bottom: 28px }
      }

      @media @desktop {
        &:nth-child(1) { font-size: 40px; }
        &:nth-child(2) { font-size: 32px; line-height: 1.4 }
      }
    }
  }

  &__img {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;

    @media @laptop {
      flex: 0 0 370px;
      order: 2;
    }
  }
}

.zuzo-reward {
  position: relative;
  width: 350px;
  padding-right: 85px;

  @media @laptop {
    padding-right: 0;
    width: 393px;
  }

  & .gif-laptop-1 {
    display: none;
    width: 200px;
    position: absolute;
    top: -120px;
    left: -120px;

    @media @laptop {
      display: block;
    }

    @media @desktop {
      top: -50px;
      left: -240px;
      width: 240px;
    }
  }

  & .gif-laptop-2 {
    display: none;
    width: 180px;
    position: absolute;
    top: 130px;
    right: -180px;

    @media @laptop {
      display: block;
    }

    @media @desktop {
      width: 156px;
      top: 90px;
      right: -120px;
    }
  }

  & .gif-laptop-3 {
    display: none;
    width: 120px;
    position: absolute;
    top: 170px;
    left: -280px;

    @media @laptop {
      display: block;
    }

    @media @desktop {
      top: -120px;
      left: -380px;
    }
  }

  & .gif-laptop-4 {
    display: none;
    width: 120px;
    position: absolute;
    top: 130px;
    right: -400px;

    @media @laptop {
      display: block;
    }

    @media @desktop {
      width: 122px;
      top: 150px;
      right: -600px;
    }
  }

  & .gif-desktop-1 {
    display: none;
    position: absolute;
    left: -720px;
    bottom: 80px;
    width: 136px;

    @media @desktop {
      display: block;
    }
  }

  &__pers {
    display: block;
    width: 100%;
  }

  &__like {
    position: absolute;
    right: 0;
    bottom: 50px;
    width: 100px;

    @media @laptop {
      display: none;
    }
  }

  & .hashtag {
    position: absolute;
    right: 0;
    bottom: 25px;
    font-weight: 500;
    font-size: 15px;
    transform: rotate(9deg);

    @media @laptop {
      display: none;
    }
  }

  &__phrase {
    position: absolute;
    top: 20px;
    right: -20px;
    transform: rotate(21deg);

    @media @laptop {
      top: -50px;
      right: -180px;
      transform: rotate(12deg);
    }

    & img {
      width: 150px;

      @media @laptop {
        width: 260px;
      }
    }

    & span {
      display: block;
      position: absolute;
      left: 20px;
      top: 24px;
      transform: rotate(10deg);
      font-size: 12px;

      @media @laptop {
        font-size: 22px;
        line-height: 1.2;
        left: 30px;
        top: 40px;
      }
    }
  }
}
</style>