<script>
export default {
  name: 'SectionWelcome',

  mounted() {
    autotypingText( {
      typingSpeed: 50,
      switchTimeout: 1500,
      element: document.getElementById( 'ticker-hola' ),
      data: [
        { text: 'HOLA' },
        { text: 'SALUT' },
        { text: 'CIAO' },
        { text: 'YO' },
        { text: 'ПРИВІТ' },
        { text: 'HELLO' },
        { text: 'KONNICHIWA' },
        { text: 'NAMASTE' },
        { text: 'ПРИВЕТ' },
        { text: 'BONHOUR' },
        { text: 'SALAM' },
        { text: 'MERHABA' },
      ],
    })

    function autotypingText( args ){

      let itemCount = Number( args.data.length );
      let curItemIndex = -1;
      let currentLength = 0;
      let theText = '';

      runTheTicker();

      function runTheTicker(){
        let theHold
        // Переход к следующему элементу
        if( currentLength === 0 ){
          curItemIndex++;
          curItemIndex = curItemIndex % itemCount;
          theText = args.data[curItemIndex].text.replace( /"/g, '-' );
          // args.element.href = args.data[curItemIndex].url;
        }
        // Располагаем текущий текст в анкор с печатанием
        args.element.innerHTML = theText.substring( 0, currentLength ) + simbol();
        // Преобразуем длину для подстроки и определяем таймер
        if( currentLength !== theText.length ){
          currentLength++;
          theHold = args.typingSpeed;
        } else {
          currentLength = 0;
          theHold = args.switchTimeout;
        }
        // Повторяем цикл с учетом задержки
        setTimeout( runTheTicker, theHold );
      }

      function simbol(){
        return ( currentLength === theText.length ) ? '' : '|';
      }
    }
  }
}
</script>

<template>
  <div class="welcome">
    <div class="container">
      <div class="star-wrapper">
        <img :src="require('@/assets/images/star.png')" alt="star">
      </div>
      <div class="welcome__inner">
        <div class="welcome__banner">
          <div class="welcome-banner">
            <div class="welcome-banner__inner">
              <div class="welcome-banner__content">
                <div class="welcome-banner__title comic">ZUZO IS LAUNCHED</div>
                <div class="welcome-banner__subtitle comic">Find it on the BLUM memepad</div>
                <div class="welcome-banner__text">
<!--                  You r early.<br>-->
                  <span>LFG bro</span>
                </div>
              </div>
              <div class="welcome-banner__img">
                <img :src="require('@/assets/images/zuzo-loading.gif')" alt="zuzo">
              </div>
            </div>
            <div class="welcome-banner__button-wrap">
              <a href="" class="carter">Join TG <img class="mr-2" :src="require('@/assets/icons/tg.svg')" alt="telegram"></a>
            </div>
          </div>
        </div>
        <div class="welcome__text">
          <div class="welcome__title comic" id="ticker-hola"></div>
          <div class="welcome__subtitle comic">Welcome to Zuzoland</div>
          <div class="welcome__description">
            <p>
              Yo what’s up legends. <span class="green">I'm Zuzo.</span> I'm very happy Capybara. You might not see it on my poker face. But trust me bro. I’m living the dream.
            </p>
            <p>
              Be ready. ZUZO is launching on BLUM - one of the most innovative memepads and the biggest one on TON. Our own tap-game is under development and will bring millions of users and buyers to $ZUZO. <span class="blue">You are here right on time.</span>
            </p>
            <p>
              Nothing is impossible for the community of real chads who love ice cream. <span class="amber">Life is too short for boring trades bro.</span><br>
              Welcome to Zuzoland - a place where every trade is an easy one. And every member is a real chad
            </p>
          </div>
        </div>
      </div>
      <div class="welcome__footer">
        <div class="zuzo-money">
          <img class="zuzo-money__pers" :src="require('@/assets/images/zuzo-money.png')" alt="zuzo">
          <img class="zuzo-money__star" :src="require('@/assets/images/star-2.png')" alt="star">
          <div class="zuzo-money__phrase">
            <img :src="require('@/assets/images/main/text-bubble.png')" alt="text-bubble">
            <span class="comic">No Jeets allowed</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/css/variables.less";

.star-wrapper {
  display: flex;
  justify-content: center;
  margin: 16px 0;
  overflow: hidden;

  @media @laptop {
    justify-content: start;
  }

  & img {
    width: 380px;

    @media @laptop {
      width: 870px;
    }
  }
}

.welcome {
  border-bottom: 3px solid #000;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media @laptop {
      gap: 44px;
      flex-wrap: nowrap;
    }

    @media @desktop {
      gap: 76px;
    }
  }

  &__banner {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    order: 1;

    @media @laptop {
      display: block;
      justify-content: unset;
      order: 2;
      flex: 1 1 44%;
    }

    @media @desktop {
      flex: 1 1 38%;
    }
  }

  &__text {
    flex: 0 0 100%;
    order: 2;

    @media @laptop {
      order: 1;
      flex: 1 1 56%;
    }

    @media @desktop {
      flex: 1 1 62%;
    }
  }

  &__title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 12px;
    text-transform: uppercase;

    @media @laptop {
      font-size: 40px;
      margin-bottom: 20px;
    }

    @media @desktop {
      font-size: 50px;
      margin-bottom: 28px;
    }
  }

  &__subtitle {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 24px;
    text-transform: uppercase;

    @media @laptop {
      font-size: 48px;
      margin-bottom: 35px;
    }

    @media @desktop {
      font-size: 56px;
      margin-bottom: 45px;
    }
  }

  &__description {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: @color-text-light;

    @media @laptop {
      text-align: left;
      font-size: 22px;
    }

    & p {
      line-height: 2;

      &:not(:last-child) {
        margin-bottom: 20px;

        @media @laptop {
          margin-bottom: 35px;
        }
      }
    }

    & span {
      display: inline-block;
      padding: 0 10px;
      border: 1px solid #000;
      border-bottom: 3px solid #000;
      border-radius: 10px;

      @media @laptop {
        border-radius: 14px;
        border: 2px solid #000;
        border-bottom: 5px solid #000;
      }
    }

    & .green { background-color: @green; }
    & .blue { background-color: @blue; }
    & .amber { background-color: @amber; }
  }

  &__footer {
    margin-top: 32px;
    display: flex;
    justify-content: start;

    @media @laptop {
      margin-top: 50px;
    }
  }
}

.zuzo-money {
  position: relative;

  @media @laptop {
    transform: translateX(-100px);
  }

  &__pers {
    width: 315px;
    display: block;

    @media @laptop {
      width: 480px;
    }

    @media @laptop {
      width: 560px;
    }
  }

  &__phrase {
    position: absolute;
    left: 65%;
    top: -5%;

    & img {
      width: 160px;

      @media @laptop {
        width: 280px;
      }
    }

    & span {
      padding: 30px 0 0 10px;
      display: block;
      transform: rotate(9.31deg);
      position: absolute;
      left: 0;
      top: 0;
      text-transform: uppercase;
      font-size: 14px;

      @media @laptop {
        width: 280px;
        font-size: 22px;
        padding: 56px 0 0 30px;
      }
    }
  }

  &__star {
    display: none;

    @media @laptop {
      display: block;
      position: absolute;
      left: 115%;
      bottom: 22%;
      width: 637px;
    }

    @media @desktop {
      bottom: 20%;
      left: 160%;
    }
  }
}

.welcome-banner {
  max-width: 400px;

  @media @laptop {
    max-width: unset;
  }

  &__inner {
    overflow: hidden;
    background-color: @amber;
    border: 3px solid #000;
    border-radius: 46px;
    border-bottom: 11px solid #000;

    @media @laptop {
      border-radius: 70px;
      border-bottom: 14px solid #000;
    }
  }

  &__content {
    padding: 48px 24px 0;

    @media @laptop {
      padding: 120px 36px 0;
    }

    @media @desktop {
      padding: 74px 36px 0;
    }
  }

  &__title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.5;

    @media @laptop {
      font-size: 40px;
      margin-bottom: 30px;
    }

    @media @desktop {
      font-size: 56px;
    }
  }

  &__subtitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 12px;
    text-transform: uppercase;
    line-height: 1.5;

    @media @laptop {
      font-size: 32px;
      margin-bottom: 40px;
    }

    @media @desktop {
      font-size: 36px;
    }
  }

  &__text {
    font-size: 28px;
    text-align: center;
    line-height: 2;

    @media @laptop {
      font-size: 40px;
    }

    & span {
      display: inline-block;
      padding: 0 20px;
      font-size: 32px;
      font-weight: 600;
      border-radius: 12px;
      border: 1px solid #000;
      border-bottom: 5px solid #000;
      background-color: #fff;

      @media @laptop {
        padding: 0 40px;
      }
    }
  }

  &__img {
    display: flex;
    justify-content: end;

    & img {
      width: 268px;

      @media @laptop {
        width: 336px;
      }

      @media @desktop {
        width: 390px;
      }
    }
  }

  &__button-wrap {
    display: flex;
    justify-content: center;
    margin-top: -42px;

    @media @laptop {
      margin-top: -70px;
    }

    @media @desktop {
      margin-top: -70px;
    }

    & a {
      height: 64px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      border: 3px solid #000;
      border-bottom: 6px solid #000;
      font-size: 30px;
      border-radius: 60px;
      background-color: @yellow;

      &:hover {
        background-color: @yellow-dark;
      }

      @media @laptop {
        height: 124px;
        font-size: 60px;
        padding: 0 40px;
        border-bottom: 8px solid #000;
      }

      @media @desktop {
        height: 124px;
        font-size: 60px;
        padding: 0 40px;
      }
    }

    & img {
      margin-left: 8px;
      width: 40px;

      @media @laptop {
        width: 80px;
      }
    }
  }
}
</style>