<script setup>

</script>

<template>
  <div id="launch" class="launch">
    <div class="launch__bg">
      <img class="mem" :src="require('@/assets/images/launch/zuzo-mem.png')" alt="zuzo">
    </div>
    <div class="container">
      <div class="launch__inner">
        <div class="launch__title comic">$ZUZO FAIR LAUNCH</div>
        <div class="launch__subtitle">
          <span>No presale. No jeest. No tax.</span>
        </div>
        <div class="circle">
          <img :src="require('@/assets/images/launch/circle-2.png')" alt="diagram">
          <span>Fairly launched on BLUM</span>
        </div>
      </div>
    </div>
    <div class="launch__cover">
      <div class="cloud-one">
        <img :src="require('@/assets/images/main/cloud.png')" alt="cloud">
      </div>
      <div class="cloud-two">
        <img :src="require('@/assets/images/main/cloud.png')" alt="cloud">
      </div>
      <img class="angel" :src="require('@/assets/images/launch/zuzo-angel.png')" alt="zuzo">
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/css/variables.less";

.launch {
  border-bottom: 3px solid #000;
  background-color: #F4FAFA;
  position: relative;
  overflow: hidden;

  &__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & .mem {
    position: absolute;
    right: 0;
    top: 200px;
    transform: scaleX(-1);
    width: 130px;
    z-index: 1;

    @media @laptop {
      transform: scaleX(1);
      right: unset;
      left: 0;
      top: 50px;
      width: 180px;
    }

    @media @desktop {
      width: 234px;
    }
  }

  &__inner {
    padding: 32px 0 120px;
    position: relative;

    @media @laptop {
      padding: 32px 0 0px;
    }
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 24px;
    position: relative;
    line-height: 1.4;

    @media @laptop {
      font-size: 48px;
    }

    @media @desktop {
      font-size: 56px;
    }
  }

  &__subtitle {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;

    & span {
      font-size: 20px;
      font-weight: 500;
      background-color: #fff;
      border: 1px solid #000;
      border-bottom: 3px solid #000;
      padding: 12px 16px;
      border-radius: 12px;

      @media @laptop {
        font-size: 28px;
      }

      @media @desktop {
        font-size: 36px;
      }
    }
  }

  &__cover {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;

    .cloud-one {
      height: 300px;
      width: 300px;
      overflow: hidden;
      position: absolute;
      bottom: -55px;
      left: 0;

      @media @laptop {
        height: 400px;
        width: 400px;
        bottom: -72px;
      }

      @media @desktop {
        height: 600px;
        width: 600px;
        bottom: -110px;
      }

      & img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    }

    .cloud-two {
      height: 300px;
      width: 300px;
      overflow: hidden;
      position: absolute;
      bottom: -24px;
      right: 0;

      @media @laptop {
        height: 400px;
        width: 400px;
        bottom: -30px;
      }

      @media @desktop {
        height: 600px;
        width: 600px;
        bottom: -45px;
      }

      & img {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }
    }

    & .angel {
      position: absolute;
      width: 360px;
      max-width: 100%;
      bottom: -50px;
      left: 50%;
      transform: translateX(-50%);

      @media @laptop {
        width: 420px;
        bottom: 300px;
        transform: translateX(calc(-50% + 500px));
      }

      @media @desktop {
        width: 485px;
        bottom: 500px;
        transform: translateX(calc(-50% + 700px));
      }
    }
  }
}

.circle {
  margin-top: -120px;
  display: flex;
  justify-content: center;
  position: relative;

  @media @laptop {
    margin-top: -110px;
    margin-bottom: -140px;
  }

  @media @desktop {
    margin-top: -120px;
  }

  & img {
    display: block;
    width: 920px;
    transform: translateX(-40px);

    @media @laptop {
      width: 1100px;
    }

    @media @desktop {
      width: 1378px;
    }
  }

  & span {
    position: absolute;
    bottom: 180px;
    left: 50%;
    transform: translateX(calc(-50% - 40px));
    border: 1px solid #000;
    border-bottom: 3px solid #000;
    background-color: #D8F1FF;
    padding: 4px 8px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    z-index: 1;

    @media @laptop {
      border: 2px solid #000;
      border-bottom: 5px solid #000;
      border-radius: 10px;
      bottom: unset;
      top: 250px;
      font-size: 18px;
      padding: 12px 20px;
      transform: translateX(calc(-50% + 270px));
    }

    @media @desktop {
      font-size: 24px;
      top: 290px;
      transform: translateX(calc(-50% + 350px));
      padding: 16px 28px;
    }
  }
}
</style>