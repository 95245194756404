<script>
export default {
  name: 'TheHeader',

  mounted() {
    // const header = document.getElementById('header');

    // window.addEventListener('scroll', function () {
    //   if (window.scrollY > 0) {
    //     header.classList.add('is-scroll');
    //   } else {
    //     header.classList.remove('is-scroll');
    //   }
    // });

    // if (window.scrollY > 0) {
    //   header.classList.add('is-scroll');
    // } else {
    //   header.classList.remove('is-scroll');
    // }

    const links = document.querySelectorAll('.nav__link[href*="#"]');
    for (let i = 0; i < links.length; i++) {
      links[i].onclick = function(e){
        e.preventDefault();
        const b = this;
        const id = b.getAttribute("href").substring(1);
        const el = document.getElementById(id);

        console.log(b)
        console.log(id)
        console.log(el)

        window.scrollTo({
          top: el.offsetTop - 76,
          behavior: "smooth"
        });
      };
    }
  }
}
</script>

<template>
  <header id="header" class="header">
    <div class="container">
      <nav class="nav">
        <a class="nav__link" href="#reward">Airdrop</a>
        <a class="nav__link" href="#roadmap">Roadmap</a>
        <a class="nav__link" href="#launch">Zuzonomics</a>
      </nav>
    </div>
  </header>
</template>

<style scoped lang="less">
@import "@/assets/css/variables.less";

.header {
  padding: 30px 0 0;
  //position: fixed;
  position: absolute;
  z-index: 10;
  width: 100%;
  transition: background-color .15s ease-out .15s, box-shadow .15s ease-out;
  box-shadow: 0 0 0px #000;
  background-color: rgba(255, 255, 255, 0);

  @media @laptop {
    padding: 80px 0 0;
  }

  &.is-scroll {
    transition: background-color .15s ease-out, box-shadow .15s ease-out .15s;
    background-color: rgba(255, 255, 255, .9);
    box-shadow: 0 0 10px #000;
  }

  & .container {
    padding: 0 30px;
  }
}

.nav {
  display: flex;
  gap: 12px;
  margin: 0 -6px;

  &__link {
    padding: 0 6px;
    font-size: 16px;
    font-weight: 700;
    transition: color .2s ease-out;

    &:hover {
      color: #7a1100;
    }

    @media @laptop {
      font-size: 20px;
    }
  }
}
</style>