<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import {Autoplay, FreeMode, Scrollbar} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';
export default {
  name: 'SectionTags',

  components: {
    Swiper,
    SwiperSlide
  },

  data() {
    return {
      swiper: false
    }
  },

  mounted() {
    if (window.innerWidth < 1440) {
      this.swiper = true
    } else {
      this.swiper = false
    }

    onresize = () => {
      if (window.innerWidth < 1440) {
        this.swiper = true
      } else {
        this.swiper = false
      }
    };
  },


  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [ FreeMode, Scrollbar, Autoplay ],
    };
  },
}
</script>

<template>
  <div class="section-tags">
    <div class="container">

      <swiper
          v-if="swiper"
          ref="swiper"
          :slides-per-view="'auto'"
          :scrollbar="true"
          :space-between="50"
          :freeMode="false"
          :autoplay="true"
          :centeredSlides="true"
          :modules="modules"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
      >
        <swiper-slide>
          <div class="board board--green">
            <div class="board__title comic">Respects</div>
            <div class="board__inner">
              <div class="board__tags">
                <div class="tags">
                  <div class="tag">BLUM & TON</div>
                  <div class="tag">brotherhood</div>
                  <div class="tag">degens</div>
                  <div class="tag">apes</div>
                  <div class="tag">honesty</div>
                  <div class="tag">chads</div>
                  <div class="tag">holders</div>
                  <div class="tag">diamond hands</div>
                  <div class="tag">top Gs</div>
                </div>
              </div>
              <div class="board__img">
                <div class="board-img board-img--respect">
                  <img :src="require('@/assets/images/tags/respect.png')" alt="respect">
                  <img :src="require('@/assets/images/tags/respect-cloud.png')" alt="respect">
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="board board--amber">
            <div class="board__title comic">Loves</div>
            <div class="board__inner">
              <div class="board__tags">
                <div class="tags">
                  <div class="tag">pizza</div>
                  <div class="tag">ice cream</div>
                  <div class="tag">freedom</div>
                  <div class="tag">traveling</div>
                  <div class="tag">x100 degen plays</div>
                  <div class="tag">skating and surfing</div>
                  <div class="tag">coctails</div>
                  <div class="tag">good movies</div>
                  <div class="tag">green PNL</div>
                  <div class="tag">sleeping</div>
                  <div class="tag">nice burgers</div>
                  <div class="tag">airdrops</div>
                  <div class="tag">sweets</div>
                  <div class="tag">Luxury stuff</div>
                  <div class="tag">supercars</div>
                  <div class="tag">chicks</div>
                </div>
              </div>
              <div class="board__img">
                <div class="board-img board-img--loves">
                  <img :src="require('@/assets/images/tags/loves.png')" alt="loves">
                  <img :src="require('@/assets/images/tags/loves-cloud-1.png')" alt="loves">
                  <img :src="require('@/assets/images/tags/loves-cloud-2.png')" alt="loves">
                  <img :src="require('@/assets/images/tags/loves-cloud-3.png')" alt="loves">
                  <img :src="require('@/assets/images/tags/loves-cloud-4.png')" alt="loves">
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="board board--gray">
            <div class="board__title comic">Hates</div>
            <div class="board__inner">
              <div class="board__tags">
                <div class="tags">
                  <div class="tag">weak hands</div>
                  <div class="tag">jeets</div>
                  <div class="tag">boring talks</div>
                  <div class="tag">being too serious</div>
                  <div class="tag">fuc*king jeets</div>
                  <div class="tag">winter</div>
                  <div class="tag">jeeters</div>
                  <div class="tag">bear market</div>
                  <div class="tag">rugs</div>
                  <div class="tag">FUDders</div>
                </div>
              </div>
              <div class="board__img">
                <div class="board-img board-img--hates">
                  <div class="hates-wrapper">
                    <img :src="require('@/assets/images/tags/hates.png')" alt="hates">
                  </div>
                  <img :src="require('@/assets/images/tags/hates-cloud-1.png')" alt="hates">
                  <img :src="require('@/assets/images/tags/hates-cloud-2.png')" alt="hates">
                  <img :src="require('@/assets/images/tags/hates-cloud-3.png')" alt="hates">
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <div v-else class="board-list">
        <div class="board-list__item">
          <div class="board board--green">
            <div class="board__title comic">Respects</div>
            <div class="board__inner">
              <div class="board__tags">
                <div class="tags">
                  <div class="tag">BLUM & TON</div>
                  <div class="tag">brotherhood</div>
                  <div class="tag">degens</div>
                  <div class="tag">apes</div>
                  <div class="tag">honesty</div>
                  <div class="tag">chads</div>
                  <div class="tag">holders</div>
                  <div class="tag">diamond hands</div>
                  <div class="tag">top Gs</div>
                </div>
              </div>
              <div class="board__img">
                <div class="board-img board-img--respect">
                  <img :src="require('@/assets/images/tags/respect.png')" alt="respect">
                  <img :src="require('@/assets/images/tags/respect-cloud.png')" alt="respect">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="board-list__item">
          <div class="board board--amber">
            <div class="board__title comic">Loves</div>
            <div class="board__inner">
              <div class="board__tags">
                <div class="tags">
                  <div class="tag">pizza</div>
                  <div class="tag">ice cream</div>
                  <div class="tag">freedom</div>
                  <div class="tag">traveling</div>
                  <div class="tag">x100 degen plays</div>
                  <div class="tag">skating and surfing</div>
                  <div class="tag">coctails</div>
                  <div class="tag">good movies</div>
                  <div class="tag">green PNL</div>
                  <div class="tag">sleeping</div>
                  <div class="tag">nice burgers</div>
                  <div class="tag">airdrops</div>
                  <div class="tag">sweets</div>
                  <div class="tag">Luxury stuff</div>
                  <div class="tag">supercars</div>
                  <div class="tag">chicks</div>
                </div>
              </div>
              <div class="board__img">
                <div class="board-img board-img--loves">
                  <img :src="require('@/assets/images/tags/loves.png')" alt="loves">
                  <img :src="require('@/assets/images/tags/loves-cloud-1.png')" alt="loves">
                  <img :src="require('@/assets/images/tags/loves-cloud-2.png')" alt="loves">
                  <img :src="require('@/assets/images/tags/loves-cloud-3.png')" alt="loves">
                  <img :src="require('@/assets/images/tags/loves-cloud-4.png')" alt="loves">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="board-list__item">
          <div class="board board--gray">
            <div class="board__title comic">Hates</div>
            <div class="board__inner">
              <div class="board__tags">
                <div class="tags">
                  <div class="tag">weak hands</div>
                  <div class="tag">jeets</div>
                  <div class="tag">boring talks</div>
                  <div class="tag">being too serious</div>
                  <div class="tag">fuc*king jeets</div>
                  <div class="tag">winter</div>
                  <div class="tag">jeeters</div>
                  <div class="tag">bear market</div>
                  <div class="tag">rugs</div>
                  <div class="tag">FUDders</div>
                </div>
              </div>
              <div class="board__img">
                <div class="board-img board-img--hates">
                  <div class="hates-wrapper">
                    <img :src="require('@/assets/images/tags/hates.png')" alt="hates">
                  </div>
                  <img :src="require('@/assets/images/tags/hates-cloud-1.png')" alt="hates">
                  <img :src="require('@/assets/images/tags/hates-cloud-2.png')" alt="hates">
                  <img :src="require('@/assets/images/tags/hates-cloud-3.png')" alt="hates">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import "@/assets/css/variables.less";

.swiper {
  --swiper-scrollbar-bg-color: #f2c63f;
  --swiper-scrollbar-drag-bg-color: #ff9900;
  --swiper-scrollbar-size: 6px;
  --swiper-scrollbar-bottom: 6px;
  padding-bottom: 30px;
}

.swiper-scrollbar {
  box-shadow: 0 1px 0px 2px #000;
}

.swiper-scrollbar-drag {
  border: 1px solid #000;
  border-bottom: 3px solid #000;
  height: 15px;
  top: -4px;
}

.swiper-slide {
  padding-top: 48px;
}

.swiper-slide {
  width: 350px;
}

.section-tags {
  padding: 60px 0;
  background-image: url('@/assets/images/bg-zuzo.png');
  overflow: hidden;
  border-bottom: 3px solid #000;
  background-size: 1000px;

  @media @laptop {
    background-size: unset;
    padding: 180px 0 60px;
  }
}

.board-list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    flex: 0 0 100%;

    &:not(:last-child) {
      margin-bottom: 80px;

      @media @laptop {
        margin-bottom: 180px;
      }
    }
  }
}

.board {
  border: 3px solid #000;
  border-bottom: 8px solid #000;
  border-radius: 35px;
  background-color: #fff;
  position: relative;
  padding: 44px 8px 20px;
  //width: 350px;
  width: 100%;

  @media @laptop {
    width: 100%;
    padding: 40px 40px 40px 60px;
  }

  &--green, &--green &__title { background-color: @green; }
  &--amber, &--amber &__title { background-color: @amber; }
  &--gray, &--gray &__title { background-color: @gray; }

  &__title {
    position: absolute;
    top: -40px;
    left: 24px;
    font-size: 36px;
    border-radius: 12px;
    border: 2px solid #000;
    border-bottom: 5px solid #000;
    width: 300px;
    height: 60px;
    background-color: #fff;
    transform: rotate(-4deg);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 700;

    @media @laptop {
      top: -68px;
      width: 600px;
      font-size: 50px;
      height: 90px;
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;

    @media @laptop {
      flex-wrap: nowrap;
    }
  }

  &__tags {
    flex: 0 0 100%;

    @media @laptop {
      padding-top: 50px;
      flex: 1 1 60%;
      padding-right: 70px;
    }
  }

  &__img {
    padding-top: 16px;
    flex: 0 0 100%;

    @media @laptop {
      padding-top: 0;
      flex: 1 1 40%;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
  row-gap: 12px;

  @media @laptop {
    column-gap: 14px;
    row-gap: 24px;
  }
}

.tag {
  border: 2px solid #000;
  border-bottom: 5px solid #000;
  height: 36px;
  border-radius: 8px;
  background-color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  padding: 0 12px;
  flex-grow: 1;

  @media @laptop {
    height: 54px;
    font-size: 24px;
    padding: 0 36px;
  }

  @media @desktop {
    padding: 0 70px;
  }
}

.board-img {
  border: 2px solid #000;
  border-bottom: 5px solid #000;
  border-radius: 24px;
  background-color: #fff;
  height: 300px;
  position: relative;

  @media @laptop {
    height: 100%;
  }

  &--respect {
    & img:nth-child(1) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    & img:nth-child(2) {
      position: absolute;
      top: 3%;
      right: 4%;
      width: 130px;
    }
  }

  &--loves {
    & img:nth-child(1) {
      position: absolute;
      right: -64px;
      bottom: -68px;
      width: 248px;
      @media @laptop {
        width: 480px;
        right: -180px;
        bottom: -130px;
      }
    }
    & img:nth-child(2) {
      position: absolute;
      top: -32px;
      right: -32px;
      width: 140px;
      @media @laptop {
        width: 220px;
        top: -60px;
        right: -60px;
      }
    }
    & img:nth-child(3) {
      position: absolute;
      top: -16px;
      left: -16px;
      width: 110px;
      @media @laptop {
        width: 180px;
        top: -30px;
        left: -30px;
      }
    }
    & img:nth-child(4) {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 143px;
      transform: translate(-50%, -50%);
      @media @laptop {
        width: 220px;
      }
    }
    & img:nth-child(5) {
      position: absolute;
      bottom: -28px;
      left: -14px;
      width: 129px;
      @media @laptop {
        width: 200px;
        bottom: -28px;
        left: -40px;
      }
    }
  }

  &--hates {
    border: unset;

    & > img:nth-child(2) {
      position: absolute;
      top: 10px;
      right: 120px;
      width: 107px;
      @media @laptop {
        top: 10px;
        right: 150px;
        width: 120px;
      }
    }

    & > img:nth-child(3) {
      position: absolute;
      top: -12px;
      right: -10px;
      width: 114px;
      @media @laptop {
        top: 30px;
        right: 10px;
        width: 140px;
      }
    }

    & > img:nth-child(4) {
      position: absolute;
      top: 120px;
      right: 5px;
      width: 128px;
      @media @laptop {
        top: 170px;
        right: 40px;
        width: 140px;
      }
    }
  }

  .hates-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 2px solid #000;
    border-bottom: 5px solid #000;
    border-radius: 24px;
    overflow: hidden;

    & img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 210px;
      @media @laptop {
        width: 280px;
      }
    }
  }
}
</style>