<script>
export default {
  name: 'TheFooter',


  mounted() {
    const wrappersList = document.querySelectorAll('.parallax');
    // const wrapper = document.querySelector('.main-group__sun');


    wrappersList.forEach((target) => {
      const layers = document.querySelectorAll('.parallax img');
      const handleParallax = (evt) => {
        //размер области просмотра
        const parallaxLeftOffset = target.getBoundingClientRect().left;
        const parallaxTopOffset = target.getBoundingClientRect().top;

        // координаты центра экрана
        const coordX = evt.clientX - parallaxLeftOffset - 0.5 * target.offsetWidth;
        const coordY = evt.clientY - parallaxTopOffset - 0.5 *  target.offsetHeight;

        const x = coordX.toFixed(2);
        const y = coordY.toFixed(2);
        target.querySelector('img').setAttribute('style', `transform: translate(${x / 10}px, ${y/10}px);`)
      };

      const reset = () => {
        layers.forEach((layer)=>{
          layer.removeAttribute('style');
        });
      }

      target.addEventListener('mousemove', handleParallax);
      target.addEventListener('mouseout', reset);
    })

    const links = document.querySelectorAll('.nav__link[href*="#"]');
    for (let i = 0; i < links.length; i++) {
      links[i].onclick = function(e){
        e.preventDefault();
        const b = this;
        const id = b.getAttribute("href").substring(1);
        const el = document.getElementById(id);

        console.log(b)
        console.log(id)
        console.log(el)

        window.scrollTo({
          top: el.offsetTop - 76,
          behavior: "smooth"
        });
      };
    }
  }
}
</script>

<template>
  <div class="footer">
    <div class="footer-group">
      <div class="footer-group__nav">
        <div class="nav">
          <a class="nav__link" href="#reward">Airdrop</a>
          <a class="nav__link" href="#roadmap">Roadmap</a>
          <a class="nav__link" href="#launch">Zuzonomics</a>
        </div>
      </div>
      <div class="footer-group__clouds">
        <img :src="require('@/assets/images/footer/cloud-1.png')" alt="cloud">
        <img :src="require('@/assets/images/footer/cloud-2.png')" alt="cloud">
      </div>
      <img class="footer-group__sand" :src="require('@/assets/images/main/sand.png')" alt="sand">
      <img class="footer-group__road" :src="require('@/assets/images/main/road.png')" alt="road">
      <div class="footer-group__person">
        <img class="footer-group__pers" :src="require('@/assets/images/main/pers.png')" alt="zuzo">
        <img class="footer-group__wind" :src="require('@/assets/images/main/wind.png')" alt="wind">
        <div class="footer-group__phrase">
          <img class="footer-group__text-bubble" :src="require('@/assets/images/main/text-bubble.png')" alt="text-bubble">
          <span class="comic">uhhmmm..... hey bro<br>I am Zuzo. Join my TG<br>see u there bro</span>
        </div>
      </div>
      <div class="footer-links">
        <a class="carter social-link social-link--insta" href="https://google.com" target="_blank">Insta<img class="mr-2" :src="require('@/assets/icons/instagram.svg')" alt="instagram"></a>
        <a class="carter social-link social-link--tg" href="https://google.com" target="_blank">TG<img class="mr-2" :src="require('@/assets/icons/tg.svg')" alt="telegram"></a>
<!--        <a class="carter social-link social-link&#45;&#45;dex" href="">DEX<img class="mr-2" :src="require('@/assets/icons/dex.svg')" alt="dex"></a>-->
        <a class="carter social-link social-link--dex" href="https://google.com" target="_blank">BUY</a>
        <a class="carter social-link social-link--x" href="https://google.com" target="_blank"><img class="mr-2" :src="require('@/assets/icons/x.svg')" alt="x"></a>
      </div>
      <img class="footer-group__sign" :src="require('@/assets/images/main/sign.png')" alt="sign">
      <img class="footer-group__flower" :src="require('@/assets/images/main/flower.png')" alt="flower">
      <div class="footer-group__rocket parallax">
        <img :src="require('@/assets/images/main/rocket.png')" alt="zuzo">
      </div>
      <div class="footer-group__copyright">
        <div class="copyright">2024. All rights reserved by LFG production</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/css/variables.less";

.footer {
  overflow: hidden;
  background-color: #F2C63F;
}

.nav {
  display: flex;
  gap: 12px;
  margin: 0 -6px;

  &__link {
    padding: 0 6px;
    font-size: 26px;
    font-weight: 700;
    transition: color .2s ease-out;

    &:hover {
      color: #7a1100;
    }
  }
}

.footer-group {
  position: relative;
  height: 430px;

  @media @laptop {
    height: 700px;
  }

  @media @desktop {
    height: 780px;
  }

  &__nav {
    display: none;

    @media @laptop {
      display: block;
      position: absolute;
      left: 300px;
      top: 100px;
      z-index: 1;
    }

    @media @desktop {
      left: 350px;
    }
  }

  &__clouds {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: flex-start;

    & img:nth-child(1) {
      width: 158px;

      @media @laptop {
        width: 330px;
      }

      @media @desktop {
        width: 388px;
      }
    }

    & img:nth-child(2) {
      width: 139px;
      margin-left: -50px;
      margin-top: 50px;

      @media @laptop {
        width: 261px;
        margin-top: 150px;
      }

      @media @desktop {
        width: 322px;
        margin-left: -120px;
      }
    }

    @media @laptop {
      left: 50%;
      transform: translateX(250px);
    }
  }

  &__person {
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px);
    transform: translateX(-50%);

    @media @laptop {
      bottom: -50px;
    }
  }

  &__pers {
    display: block;
    width: 200px;

    @media @laptop {
      width: 410px;
    }

    @media @desktop {
      width: 434px;
    }
  }

  &__wind {
    position: absolute;
    top: 0;
    right: 60%;
    width: 150px;

    @media @laptop {
      width: 330px;
    }

    @media @desktop {
      width: 365px;
    }
  }

  &__phrase {
    position: absolute;
    display: none;

    @media @laptop {
      display: block;
      left: 93%;
      top: 10%;
    }

    @media @desktop {

    }

    & span {
      padding: 16px 0px 20px 12px;
      position: absolute;
      left: 0;
      top: 0;
      transform: rotate(9.31deg);
      font-size: 10px;

      @media @laptop {
        font-size: 20px;
        padding: 30px 0 0 50px;
        line-height: 1.3;
      }
    }
  }

  &__text-bubble {
    width: 130px;

    @media @laptop {
      width: 300px;
    }
  }

  &__road {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 730px;

    @media @laptop {
      width: 100%;
    }
  }

  &__sand {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 730px;

    @media @laptop {
      width: 100%;
    }
  }

  &__flower {
    position: absolute;
    bottom: 60px;
    right: 50%;
    width: 50px;
    transform: translateX(160px);

    @media @laptop {
      width: 125px;
      bottom: 120px;
      transform: translateX(300px);
    }

    @media @desktop {
      bottom: 140px;
      transform: translateX(550px);
    }
  }

  &__sign {
    display: none;

    @media @laptop {
      display: block;
      position: absolute;
      bottom: 100px;
      right: 50%;
      width: 263px;
      transform: translateX(720px);
    }

    @media @desktop {
      width: 303px;
      bottom: 130px;
      transform: translateX(900px);
    }
  }

  &__rocket {
    position: absolute;
    display: none;

    @media @laptop {
      width: 200px;
      display: block;
      top: 30px;
      left: 40px;
    }

    @media @desktop {
      width: 250px;
      top: 50px;
    }

    & img {
      display: block;
      width: 100%;
    }
  }

  &__copyright {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 24px 10px;
    width: 100%;

    @media @laptop {
      text-align: right;
      padding: 0 60px 14px;
    }

    @media @desktop {
      padding: 0 120px 14px;
    }
  }
}

.footer-links {
  width: 120px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;

  @media @laptop {
    right: unset;
    top: unset;
    left: 60px;
    bottom: 100px;
    width: 200px;
  }

  .social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    height: 54px;
    padding: 0 8px;
    border: 2px solid #000;
    border-bottom: 4px solid #000;
    border-radius: 27px;
    background-color: #fff;
    gap: 4px;

    @media @laptop {
      gap: 8px;
      height: 80px;
      font-size: 40px;
      padding: 0 20px;
      border-radius: 40px;
      border-bottom: 6px solid #000;
    }

    @media @desktop {
      padding: 0 30px;
    }

    & img {
      height: 24px;

      @media @laptop {
        height: 52px;
      }
    }

    &--insta { background-color: @amber; }
    &--tg { background-color: @blue; }
    &--dex { background-color: #A6FFB0; }
    &--x { padding: 0 20px; background-color: @yellow; }

    &--insta:hover { background-color: @amber-dark; }
    &--tg:hover { background-color: @blue-dark; }
    &--dex:hover { background-color: #46FF5B; }
    &--x:hover { background-color: @yellow-dark; }

    @media @laptop {
      &--x { padding: 0 50px; }
    }
  }
}

.copyright {
  color: #fff;
  font-size: 12px;

  @media @laptop {
    font-size: 14px;
  }
}
</style>