<script>
import SectionMain from "@/components/SectionMain.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMarquee from "@/components/TheMarquee.vue";
import SectionWelcome from "@/components/SectionWelcome.vue";
import SectionTags from "@/components/SectionTags.vue";
import SectionRoad from "@/components/SectionRoad.vue";
import SectionReward from "@/components/SectionReward.vue";
import SectionLaunch from "@/components/SectionLaunch.vue";
// import SectionMemelaxy from "@/components/SectionMemelaxy.vue";
import TheFooter from "@/components/TheFooter.vue";

export default {
  name: 'App',
  components: {
    TheFooter,
    // SectionMemelaxy,
    SectionLaunch,
    SectionReward,
    SectionRoad,
    SectionTags,
    SectionWelcome,
    TheMarquee,
    TheHeader,
    SectionMain,
  }
}
</script>

<template>
  <TheHeader />
  <main>
    <SectionMain />
    <TheMarquee />
    <SectionWelcome />
    <SectionReward />
    <SectionRoad />
    <TheMarquee />
    <SectionLaunch />
    <SectionTags />
<!--    <SectionMemelaxy />-->
    <TheFooter />
  </main>
</template>

<style lang="less">
@import "@/assets/css/main.less";
</style>
