<script>
export default {
  name: 'SectionWelcome',

  mounted() {
    const list = document.getElementsByClassName('inf');
    let infPosList = [];
    const base = document.documentElement.clientHeight / 100 * 70;

    for (let i = 0; i < list.length; i++) {
      infPosList[i] = list[i].getBoundingClientRect().top;
    }
    for (let i = 0; i < list.length; i++) {
      if (infPosList[i] < base) {
        list[i].classList.add('is-active');
      } else {
        list[i].classList.remove('is-active');
      }
    }

    window.addEventListener('scroll', function () {
      for (let i = 0; i < list.length; i++) {
        infPosList[i] = list[i].getBoundingClientRect().top;
      }
      for (let i = 0; i < list.length; i++) {
        if (infPosList[i] < base) {
          list[i].classList.add('is-active');
        } else {
          list[i].classList.remove('is-active');
        }
      }
    });

  }
}
</script>

<template>
  <div id="roadmap" class="section-road">
    <picture class="section-road__bg">
      <source media="(min-width: 1920px)" :srcset="require('@/assets/images/road/road-desktop.png')">
      <source media="(min-width: 1440px)" :srcset="require('@/assets/images/road/road-laptop.png')">
      <img :src="require('@/assets/images/road/road-mob.png')" alt="">
    </picture>
<!--    <div class="section-road__bg">-->
<!--      <img :src="require('@/assets/images/road/road-mob.png')" alt="roadmap">-->
<!--    </div>-->
    <div class="container">
      <div class="road-group">
        <div class="inf inf--blue">
          <div class="inf__title comic">
            <img :src="require('@/assets/images/road/marker.png')" alt="marker">
            <span>EARLY ADOPTERS</span>
          </div>
          <div class="inf__text">
            <ul>
              <li>Website launch</li>
              <li>First giveaways</li>
              <li>Launch on BLUM memepad</li>
              <li>First 10000+ ZUZO fans</li>
            </ul>
          </div>
        </div>
        <div class="inf inf--green">
          <div class="inf__title comic">
            <img :src="require('@/assets/images/road/marker.png')" alt="marker">
            <span>ZUZO LAUNCH</span>
          </div>
          <div class="inf__text">
            <ul>
              <li>Successful fair launch on BLUM memepad</li>
              <li>Unique animated content</li>
              <li>ZUZO is getting viral</li>
              <li>Onboarding 10000 of new members in TG = ZUZO Bro-hood</li>
              <li>New collaborations</li>
              <li>Community is growing</li>
              <li>Price -> higher</li>
              <li>More influencers are getting onboard</li>
              <li>More contests and giveaway to the community</li>
            </ul>
          </div>
        </div>
        <div class="inf inf--amber">
          <div class="inf__title comic">
            <img :src="require('@/assets/images/road/marker.png')" alt="marker">
            <span>Expansion</span>
          </div>
          <div class="inf__text">
            <ul>
              <li>More influencers</li>
              <li>Blum Memepad bonding curve is filled Bonding curve: 9165 TO</li>
              <li>Tap Game is ready</li>
              <li>5 000 000$ mcap - achieved</li>
              <li>First CEX Listings Negotiations</li>
              <li>ZUZO is trending</li>
              <li>More paid ads</li>
              <li>Coinmarketcap - paid</li>
              <li>Coingecko - paid</li>
              <li>5000 of Zuzo holders</li>
              <li>More fun = more money</li>
            </ul>
          </div>
        </div>
        <div class="inf inf--blue">
          <div class="inf__title comic">
            <img :src="require('@/assets/images/road/marker.png')" alt="marker">
            <span>MEMELAXY</span>
          </div>
          <div class="inf__text">
            <ul>
             <li>ZUZO is the owner of MEMELAXY ecosystem</li>
             <li>Memelaxy app (memecoins airdrop marketplace)</li>
             <li>Tier-1 partnerships</li>
             <li>Tier-1 KOLs</li>
             <li>Global Marketing team</li>
             <li>ZUZO-world online-rave</li>
            </ul>
          </div>
        </div>
        <div class="inf inf--green">
          <div class="inf__title comic">
            <img :src="require('@/assets/images/road/marker.png')" alt="marker">
            <span>Rewards</span>
          </div>
          <div class="inf__text">
            <ul>
              <li>Airdrop for our ZUZO Holders</li>
              <li>Exclusive merch gifts for early adopters</li>
              <li>New rewards TBA</li>
            </ul>
          </div>
        </div>
        <div class="inf inf--amber">
          <div class="inf__title comic">
            <img :src="require('@/assets/images/road/marker.png')" alt="marker">
            <span>GALAXY DOMINATION</span>
          </div>
          <div class="inf__text">
            <ul>
              <li>Tier-1 CEX listings</li>
              <li>MEMELAXY meetups and global ZUZO festival</li>
              <li>100 00+  ZUZO holders</li>
              <li>More info TBA</li>
            </ul>
          </div>
        </div>
        <img class="zuzo-cloud" :src="require('@/assets/images/road/zuzo-cloud.png')" alt="zuzo">
      </div>
    </div>
    <img class="zuzo" :src="require('@/assets/images/main/angular.png')" alt="zuzo">
  </div>
</template>

<style scoped lang="less">
@import "@/assets/css/variables.less";

.section-road {
  border-bottom: 3px solid #000;
  position: relative;
  height: 2040px;
  overflow: hidden;
  background-color: #F4FAFA;

  @media @laptop {
    height: 2787px;
  }

  @media @desktop {
    height: 2914px;
  }

  &__bg {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);

    & img {

    }
  }

  & .container {
    height: 100%;
    position: relative;
  }

  & .zuzo {
    width: 130px;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: scaleX(-1);

    @media @laptop {
      width: 320px;
      transform: scaleX(1);
      right: unset;
      left: 0;
    }

    @media @desktop {
      width: 300px;
    }
  }
}

.road-group {
  height: 100%;
  position: relative;

  & .inf {
    position: absolute;
    width: 280px;

    @media @laptop {
      width: 400px;
    }

    @media @desktop {
      width: 580px;
    }

    &:nth-child(1) { left: 0; top: 240px; }
    &:nth-child(2) { right: -10px; top: 460px; }
    &:nth-child(3) { left: 0px; top: 760px; }
    &:nth-child(4) { right: -10px; top: 1090px; }
    &:nth-child(5) { left: 0px; top: 1300px; }
    &:nth-child(6) { right: -10px; top: 1500px; }

    @media @laptop {
      &:nth-child(1) { left: unset; right: 0; top: 240px; }
      &:nth-child(2) { right: unset; left: 0; top: 700px; }
      &:nth-child(3) { left: unset; right: 0; top: 800px; }
      &:nth-child(4) { right: unset; left: 0; top: 1300px; }
      &:nth-child(5) { left: unset; right: 0; top: 1800px; width: 350px; }
      &:nth-child(6) { right: unset; left: 0; top: 2100px; }
    }

    @media @desktop {
      &:nth-child(1) { right: 0; top: 300px; }
      &:nth-child(2) { left: 0; top: 750px; }
      &:nth-child(3) { right: 0; top: 1000px; }
      &:nth-child(4) { left: 0; top: 1350px; }
      &:nth-child(5) { right: 0; top: 1900px; width: 580px; }
      &:nth-child(6) { left: 0; top: 2250px; }
    }
  }

  & .zuzo-cloud {
    width: 200px;
    position: absolute;
    right: -10px;
    top: 955px;

    @media @laptop {
      width: 387px;
      right: 100px;
      top: 1300px;
    }

    @media @desktop {
      top: 1580px;
    }
  }
}

.inf {
  transition: transform .3s ease-out;

  &__title {
    font-size: 20px;
    font-weight: 700;
    position: relative;
    margin-bottom: 4px;
    border: 2px solid #000;
    border-bottom: 5px solid #000;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    text-align: center;
    line-height: 1.2;
    background-color: #fff;

    transition: background-color .3s ease-out;

    @media @laptop {
      font-size: 40px;
      padding: 8px 24px;
      margin-bottom: 8px;
    }

    & img {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-50%, -50%);
      width: 30px;

      @media @laptop {
        width: 73px;
        top: -16px;
      }
    }

    & span {
      display: block;
    }
  }

  //&--green &__title { background-color: @green; }
  //&--blue &__title { background-color: @blue; }
  //&--amber &__title { background-color: @amber; }

  &.is-active {
    &.inf--green .inf__title { background-color: @green; }
    &.inf--blue .inf__title { background-color: @blue; }
    &.inf--amber .inf__title { background-color: @amber; }
  }

  @media @laptop {
    transform: scale(.9) translateY(100px);

    &.is-active {
      transform: scale(1);
    }
  }

  &__text {
    border: 2px solid #000;
    border-bottom: 5px solid #000;
    background-color: #fff;
    border-radius: 14px;
    padding: 12px;

    @media @laptop {
      padding: 12px 16px;
    }

    @media @desktop {
      padding: 16px 24px;
    }

    & ul {

      & li {
        padding-left: 10px;
        position: relative;
        font-size: 11px;
        font-weight: 500;
        line-height: 1.3;
        color: @color-text-light;

        @media @laptop {
          padding-left: 16px;
          font-size: 16px;
        }

        @media @desktop {
          font-size: 24px;
          padding-left: 24px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 4px;
          left: 0;
          width: 5px;
          height: 5px;
          background-color: #000;
          border-radius: 100%;

          @media @laptop {
            top: 5px;
            width: 6px;
            height: 6px;
          }

          @media @desktop {
            top: 9px;
            width: 8px;
            height: 8px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 5px;

          @media @laptop {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>