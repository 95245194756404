<script>
export default {
  name: 'SectionMain',

  mounted() {
    const wrappersList = document.querySelectorAll('.parallax');
    // const wrapper = document.querySelector('.main-group__sun');


    wrappersList.forEach((target) => {
      const layers = document.querySelectorAll('.parallax img');
      const handleParallax = (evt) => {
        //размер области просмотра
        const parallaxLeftOffset = target.getBoundingClientRect().left;
        const parallaxTopOffset = target.getBoundingClientRect().top;

        // координаты центра экрана
        const coordX = evt.clientX - parallaxLeftOffset - 0.5 * target.offsetWidth;
        const coordY = evt.clientY - parallaxTopOffset - 0.5 *  target.offsetHeight;

        const x = coordX.toFixed(2);
        const y = coordY.toFixed(2);
        target.querySelector('img').setAttribute('style', `transform: translate(${x / 10}px, ${y/10}px);`)
      };

      const reset = () => {
        layers.forEach((layer)=>{
          layer.removeAttribute('style');
        });
      }

      target.addEventListener('mousemove', handleParallax);
      target.addEventListener('mouseout', reset);
    })

    // const handleParallax = (evt) => {
    //   //размер области просмотра
    //   const parallaxLeftOffset = wrapper.getBoundingClientRect().left;
    //   const parallaxTopOffset = wrapper.getBoundingClientRect().top;
    //
    //   // координаты центра экрана
    //   const coordX = evt.clientX - parallaxLeftOffset - 0.5 * wrapper.offsetWidth;
    //   const coordY = evt.clientY - parallaxTopOffset - 0.5 *  wrapper.offsetHeight;
    //
    //   layers.forEach((layer)=>{
    //     const x = coordX.toFixed(2);
    //     const y = coordY.toFixed(2);
    //     layer.setAttribute('style', `transform: translate(${x / 10}px, ${y/10}px);`)
    //   });
    // };

    // const reset = () => {
    //   layers.forEach((layer)=>{
    //     layer.removeAttribute('style');
    //   });
    // }

    // wrapper.addEventListener('mousemove', handleParallax);
    // wrapper.addEventListener('mouseout', reset);



    autotypingText( {
      typingSpeed: 50,
      switchTimeout: 1500,
      element: document.getElementById( 'ticker' ),
      data: [
        { text: 'awesome' },
        { text: 'lit' },
        { text: 'cool' },
        { text: 'chad' },
        { text: 'the great' },
        { text: 'turbo' },
        { text: 'chilled' },
      ],
    } )

    function autotypingText( args ){

      let itemCount = Number( args.data.length );
      let curItemIndex = -1;
      let currentLength = 0;
      let theText = '';

      runTheTicker();

      function runTheTicker(){
        let theHold
        // Переход к следующему элементу
        if( currentLength === 0 ){
          curItemIndex++;
          curItemIndex = curItemIndex % itemCount;
          theText = args.data[curItemIndex].text.replace( /"/g, '-' );
          // args.element.href = args.data[curItemIndex].url;
        }
        // Располагаем текущий текст в анкор с печатанием
        args.element.innerHTML = theText.substring( 0, currentLength ) + simbol();
        // Преобразуем длину для подстроки и определяем таймер
        if( currentLength !== theText.length ){
          currentLength++;
          theHold = args.typingSpeed;
        } else {
          currentLength = 0;
          theHold = args.switchTimeout;
        }
        // Повторяем цикл с учетом задержки
        setTimeout( runTheTicker, theHold );
      }

      function simbol(){
        return ( currentLength === theText.length ) ? '' : '|';
      }
    }
  }
}
</script>



<template>
  <div id="section-main" class="main">
    <div class="main__logo">
      <div class="main-logo">
        <img :src="require('@/assets/images/main/logo.png')" alt="logo">
        <div id="ticker" class="main-logo__text comic"></div>
      </div>
    </div>
    <div class="main__group">
      <div class="main-group">
        <div class="main-group__cloud-one">
          <img :src="require('@/assets/images/main/cloud.png')" alt="cloud">
        </div>
        <div class="main-group__cloud-two">
          <img :src="require('@/assets/images/main/cloud.png')" alt="cloud">
        </div>
        <img class="main-group__sand" :src="require('@/assets/images/main/sand.png')" alt="sand">
        <img class="main-group__palm" :src="require('@/assets/images/main/palm.png')" alt="palm">
        <img class="main-group__road" :src="require('@/assets/images/main/road.png')" alt="road">
        <img class="main-group__sign" :src="require('@/assets/images/main/sign.png')" alt="sign">
        <img class="main-group__flower" :src="require('@/assets/images/main/flower.png')" alt="flower">
        <div class="main-group__person">
          <img class="main-group__pers" :src="require('@/assets/images/main/pers.png')" alt="zuzo">
          <img class="main-group__wind" :src="require('@/assets/images/main/wind.png')" alt="wind">
          <div class="main-group__phrase">
            <img class="main-group__text-bubble" :src="require('@/assets/images/main/text-bubble.png')" alt="text-bubble">
            <span class="comic">uhhmmm..... hey bro<br>I am Zuzo. Join my TG<br>see u there bro</span>
          </div>
          <a class="carter social-link social-link--insta" href="https://google.com" target="_blank">Insta<img class="mr-2" :src="require('@/assets/icons/instagram.svg')" alt="instagram"></a>
          <a class="carter social-link social-link--tg" href="https://google.com" target="_blank">TG<img class="mr-2" :src="require('@/assets/icons/tg.svg')" alt="telegram"></a>
<!--          <a class="carter social-link social-link&#45;&#45;dex" href="">DEX<img class="mr-2" :src="require('@/assets/icons/dex.svg')" alt="dex"></a>-->
          <a class="carter social-link social-link--dex" href="https://google.com" target="_blank">BUY</a>
          <a class="carter social-link social-link--x" href="https://google.com" target="_blank"><img class="mr-2" :src="require('@/assets/icons/x.svg')" alt="x"></a>
        </div>
        <div class="main-group__sun parallax">
          <img id="sun" :src="require('@/assets/images/main/sun.png')" alt="sun">
        </div>
        <div class="main-group__rocket parallax">
          <img :src="require('@/assets/images/main/rocket.png')" alt="zuzo">
        </div>
        <img class="main-group__angular" :src="require('@/assets/images/main/angular.png')" alt="zuzo">
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/css/variables.less";

.main-logo {
  position: relative;

  & img {
    width: 100%;
    max-width: 390px;

    @media @laptop {
      max-width: 770px;
    }
  }

  &__text {
    position: absolute;
    right: 225px;
    bottom: 135px;
    font-size: 22px;
    text-transform: capitalize;

    @media @laptop {
      font-size: 34px;
      right: 460px;
      bottom: 260px;
    }
  }
}

.main {
  overflow: hidden;
  background-color: #f4fafa;
  border-bottom: 3px solid #000;

  &__logo {
    padding-top: 70px;
    display: flex;
    justify-content: center;

    @media @laptop {
      padding-top: 0px;
    }
  }

  &__group {}

  .main-group {
    //overflow: hidden;
    margin-top: -15px;
    height: 565px;
    position: relative;

    @media @laptop {
      height: 550px;
    }

    @media @desktop {
      height: 750px;
    }

    &__cloud-one {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      width: 600px;
      height: 250px;
      overflow: hidden;

      @media @laptop {
        height: 400px;
        left: 0;
        bottom: 60px;
        transform: translateX(0);
      }

      @media @desktop {
        height: 600px;
        bottom: 88px;
      }

      & img {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 900px;

        @media @laptop {
          width: 1200px;
          bottom: 50px;
        }

        @media @desktop {
          width: 1800px;
        }
      }
    }

    &__cloud-two {
      position: absolute;
      bottom: 40px;
      right: 50%;
      transform: translateX(50%);
      width: 700px;
      height: 250px;
      overflow: hidden;

      @media @laptop {
        height: 350px;
        right: 0;
        bottom: 50px;
        transform: translateX(0);
      }

      @media @desktop {
        bottom: 85px;
        height: 450px;
      }

      & img {
        position: absolute;
        right: 50px;
        bottom: 0;
        width: 1200px;

        @media @laptop {
          width: 1300px;
          right: 0;
          bottom: 50px;
        }

        @media @desktop {
          width: 1800px;
        }
      }
    }

    &__road {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 730px;

      @media @laptop {
        width: 100%;
      }
    }

    &__sand {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 730px;

      @media @laptop {
        width: 100%;
      }
    }

    &__palm {
      position: absolute;
      bottom: 240px;
      left: -54px;
      width: 224px;
      transform: rotate(9deg);

      @media @laptop {
        bottom: 100px;
        left: 50%;
        width: 330px;
        transform: rotate(0) translateX(-770px);
      }

      @media @desktop {
        width: 416px;
        transform: rotate(0) translateX(-950px);
      }
    }

    &__flower {
      display: none;

      @media @laptop {
        display: block;
        position: absolute;
        bottom: 100px;
        right: 50%;
        width: 100px;
        transform: translateX(450px);
      }

      @media @desktop {
        width: 157px;
        bottom: 140px;
        transform: translateX(550px);
      }
    }

    &__sign {
      display: none;

      @media @laptop {
        display: block;
        position: absolute;
        bottom: 100px;
        right: 50%;
        width: 263px;
        transform: translateX(680px);
      }

      @media @desktop {
        width: 344px;
        bottom: 130px;
        transform: translateX(900px);
      }
    }

    &__sun {
      position: absolute;
      top: 0;
      right: 24px;
      width: 115px;
      transform: scaleX(-1) rotate(30deg);

      @media @laptop {
        right: unset;
        top: -150px;
        left: 50%;
        width: 200px;
        transform: scaleX(1) rotate(0deg) translateX(-500px);
      }

      @media @desktop {
        top: -200px;
      }

      & img {
        display: block;
        width: 100%;
      }
    }

    &__rocket {
      position: absolute;
      top: -330px;
      right: 50%;
      display: none;
      transform: translateX(600px);

      @media @laptop {
        display: block;
      }

      @media @desktop {
        transform: translateX(650px);
      }

      & img {
        display: block;
        width: 100%;
      }
    }

    &__person {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      @media @laptop {
        bottom: -50px;
      }
    }

    &__pers {
      display: block;
      width: 230px;

      @media @laptop {
        width: 430px;
      }

      @media @desktop {
        width: 544px;
      }
    }

    &__wind {
      position: absolute;
      top: 0;
      right: 60%;
      width: 168px;

      @media @laptop {
        width: 325px;
      }

      @media @desktop {
        width: 408px;
      }
    }

    &__phrase {
      position: absolute;
      left: 75%;
      top: -5%;
      transform: rotate(-25deg);

      @media @laptop {
        left: 78%;
        top: 0%;
        transform: rotate(0deg);
      }

      @media @desktop {
        left: 93%;
        top: 6%;
      }

      & span {
        padding: 16px 0px 20px 12px;
        position: absolute;
        left: 0;
        top: 0;
        transform: rotate(9.31deg);
        font-size: 10px;

        @media @laptop {
          font-size: 20px;
          padding: 30px 0 0 50px;
          line-height: 1.3;
        }
      }
    }

    &__text-bubble {
      width: 130px;

      @media @laptop {
        width: 300px;
      }
    }

    .social-link {
      position: absolute;
      display: flex;
      align-items: center;
      font-size: 20px;
      height: 44px;
      padding: 0 8px;
      border: 2px solid #000;
      border-radius: 22px;
      background-color: #fff;
      gap: 4px;

      @media @laptop {
        gap: 8px;
        height: 80px;
        font-size: 40px;
        padding: 0 20px;
        border-radius: 40px;
        border-bottom: 6px solid #000;
      }

      @media @desktop {
        padding: 0 30px;
        height: 94px;
        border-radius: 47px;
      }

      & img {
        height: 24px;

        @media @laptop {
          height: 52px;
        }
      }

      &--insta { top: 33%; right: 75%; transform: rotate(12deg); background-color: @amber; }
      &--tg { top: 45%; left: 80%; transform: rotate(3deg); background-color: @blue; }
      &--dex { bottom: 34%; right: 95%; transform: rotate(-12deg); background-color: #A6FFB0; }
      &--x { bottom: 25%; left: 84%; transform: rotate(13deg); padding: 0 20px; background-color: @yellow; }

      &--insta:hover { background-color: @amber-dark; }
      &--tg:hover { background-color: @blue-dark; }
      &--dex:hover { background-color: #46FF5B; }
      &--x:hover { background-color: @yellow-dark; }

      @media @laptop {
        &--x { padding: 0 50px; }
      }

      @media @desktop {
        &--insta { right: 80%; }
        &--tg { top: 40%; left: 102%; transform: rotate(18deg);  }
        &--dex { right: 100%; }
        &--x { bottom: 35%; left: 86%; transform: rotate(15deg); padding: 0 60px; }
      }
    }

    &__angular {
      position: absolute;
      bottom: 0;
      left: -15px;
      width: 100px;

      @media @laptop {
        left: 0;
        width: 262px;
      }

      @media @desktop {
        width: 310px;
      }
    }
  }
}
</style>